import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";

const ForgotPassword = () => {

  const [errors, setErrors] = useState(null)

  const _resetPassword = () => { }
  const _handleOnChange = () => { }

  return (
    <div className="full-page login-page">

      <div className="authFormWrap">
        <Form onSubmit={(e) => _resetPassword(e)}>
          <Card className="card-white">
            <CardHeader>
              <img alt="EVOS Logo" className="companyLogo" src="../../../assets/img/evos-logo.png" style={{ marginBottom: 45 }} />

              <h1 className="card-title">Forgot Password</h1>
            </CardHeader>
            <CardBody>
              <Label>Email</Label>
              <FormGroup>
                <i className="fa fa-envelope-o formIcon"></i>
                <Input
                  placeholder="Enter"
                  type="email"
                  // value={user.email}
                  onChange={(e) =>
                    _handleOnChange("email", e.target.value)
                  }
                />
                {errors && (
                  <div className="validation-error">
                    {errors.email}
                  </div>
                )}
              </FormGroup>

              <Button
                className="btn themeBtn"
                type="submit"
              >
                Reset Password
              </Button>

              <Link className="link createAccount" to="/login">
                Back to <span>Login</span>
              </Link>
            </CardBody>
          </Card>
        </Form>
      </div>

      <div className="authFooter">
        EVOS &#169; {new Date().getFullYear()}.

        <div style={{ display: 'none' }}>
          Powered By: <a href="https://www.logic-square.com/" target="_blank" rel="noreferrer">Logic Square</a>
        </div>
        <div>
          Powered By: <a href="http://www.gurooit.com" target="_blank" rel="noreferrer">GuROO</a>
        </div>
      </div>
    </div>
  )
}


export default ForgotPassword