import './App.css';
import { Switch, BrowserRouter, Redirect } from 'react-router-dom'
import PublicRoute from './modules/general/components/public-route'
import Login from './modules/public/pages/login';
import ForgotPassword from './modules/public/pages/forgot-password'
import Dashboard from './modules/private/dashboard';
import Layout from './modules/general/container/layout'
import ProtectedRoute from './modules/general/components/protected-route';

function App() {
  console.log = () => {}
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute path="/login" component={Login} redirectRoute="/dashboard/licenses" />
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <PublicRoute path="/forgot-password" component={ForgotPassword} />
        <PublicRoute path="/" component={Layout} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
