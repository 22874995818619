import React, { useState, useEffect } from 'react'
import { Table, Badge, Button, Card, CardHeader, CardBody, Row, Col } from 'reactstrap'
import UserInfoModal from '../components/Modals/UserInfoModal'
import { getUserInfo } from '../../http/http-calls'


const UserInfo = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [users, setUsers] = useState(null);

    useEffect(() => {
        _init()
    }, [])

    const _init = async () => {
        try {
            const userinfo = await getUserInfo()
            console.log(userinfo)
            setUsers(userinfo.users)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <div className="content">
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Card className="userInfo">
                            <CardHeader>Clients</CardHeader>
                            <CardBody>


                                <Table className="tablesorter" responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ width: 100 }}>
                                                Sr. no.
                                            </th>
                                            <th>User</th>
                                            <th>Status</th>
                                            <th>Last Updated</th>
                                            <th style={{ width: 150, textAlign: 'right' }}>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { users && users.map((each, index) => <tr>
                                            <td>
                                                {index}
                                            </td>
                                            <td>
                                                {each.name}
                                            </td>
                                            <td>
                                                {/* for active use success and for stop use danger  */}
                                                <Badge color="success">Active</Badge>
                                            </td>
                                            <td>
                                                25 March 2022 12:30 AM
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <Button className="viewBtn" onClick={toggle}>
                                                    See More
                                                </Button>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <UserInfoModal open={modal} toggle={toggle} />
        </>
    )
}

export default UserInfo
