import React from 'react';
import {
  Col, Row,
  Button,
  Form, FormGroup, Label, Input, Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';

const NewLicence = (props) => {

  const generateLicense = () => {
    props.onSave(props.lisence)
  }

  return (
    <>
      <Modal isOpen={props.open} toggle={props.toggle} className="modal-dialog-centered  modal-dialog-scrollable">
        <ModalHeader toggle={props.toggle}>Create Licence</ModalHeader>
        <ModalBody>
          <Form>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input type="text" name="name" value={props.lisence.name} onChange={(e) => props.handleOnChange("name", e.target.value)} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input type="email" name="email" value={props.lisence.email} onChange={(e) => props.handleOnChange("email", e.target.value)} />
                </FormGroup>
              </Col>
              {/* <Col md={6}>
                <FormGroup>
                  <Label>Start Date</Label>
                  <Input type="date" name="date" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>End Date</Label>
                  <Input type="date" name="date" />
                </FormGroup>
              </Col> */}
              <Col md={6}>
                <FormGroup>
                  <Label>Validity in days</Label>
                  <Input type="number" name="licence" value={props.lisence.validityInDays} onChange={(e) => props.handleOnChange("validityInDays", e.target.value)} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Count</Label>
                  <Input type="number" name="count" value={props.lisence.noOfLicenses} onChange={(e) => props.handleOnChange("noOfLicenses", e.target.value)} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Phone</Label>
                  <Input type="text" name="phone" value={props.lisence.phone} onChange={(e) => props.handleOnChange("phone", e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="modalBtnCancel" onClick={() => props.toggle()}>Cancel</Button>
          <Button color="secondary" className="modalBtnSave" onClick={generateLicense}>Save</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default NewLicence;
