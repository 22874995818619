/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Link } from "react-router-dom";

// reactstrap components
import {
  // Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  //Input,
  // InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  //Modal
} from "reactstrap";

const AdNavbar = (props) => {
  // const toggleOpen = () => {

  // }

  const [collapseOpen, setCollapseOpen] = useState(true)
  const [color, setColor] = useState("bg-dark, navbar-transparent")

  const _myProfile = () => { }
  const _logout = () => { }
  const toggleCollapse = () => { }

  return (
    <Navbar
      className={classNames("navbar-absolute", color)}
      expand="lg"
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div
            className={"navbar-toggle d-inline"}
          >
            <button
              className="navbar-toggler"
              type="button"
            // onClick={this.props.toggleSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
            <img alt="EVOS Logo" className="sideMenuLogo" src="../../../../assets/img/evos-logo-1.png" />
          </NavbarBrand>
        </div>
        {props?.pageName && <div className="lab-name">{props?.pageName}</div>}
        <button
          aria-expanded={false}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-target="#navigation"
          data-toggle="collapse"
          id="navigation"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse navbar isOpen={collapseOpen}>
          <Nav className="ml-auto navbar_list" navbar>
            <NavItem>
              <Link to="/dashboard/licenses/">Licences</Link>
            </NavItem>
            <NavItem>
              <Link to="/dashboard/client/">Client</Link>
            </NavItem>
            <NavItem>
              <Link to="/dashboard/templates">Templates</Link>
            </NavItem>
            <NavItem>
              <Link to="/dashboard/updates">Updates</Link>
            </NavItem>
            <NavLink>
              <Link to="/dashboard/userinfo">User Info</Link>
            </NavLink>
          </Nav>
          <Nav className="ml-auto navbar_list" navbar>

            {/* <InputGroup className="search-bar">
                  <Button
                    color="link"
                    data-target="#searchModal"
                    data-toggle="modal"
                    id="search-button"
                    onClick={this.toggleModalSearch}
                  >
                    <i className="tim-icons icon-zoom-split" />
                    <span className="d-lg-none d-md-block">Search</span>
                  </Button>
                </InputGroup> */}
            {/* <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                  >
                    <div className="notification d-none d-lg-block d-xl-block" />
                    <i className="tim-icons icon-sound-wave" />
                    <p className="d-lg-none">Notifications</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Mike John responded to your email
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        You have 5 more tasks
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Your friend Michael is in town
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Another notification
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Another one
                      </DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
            <UncontrolledDropdown nav>
              <DropdownToggle
                caret
                color="default"
                data-toggle="dropdown"
                nav
                onClick={e => e.preventDefault()}
              >
                <div className="photo">
                  <img alt="..." src="../../../../assets/img/anime3.png" />
                </div>
                <b className="caret d-none d-lg-block d-xl-block" />
                <p className="d-lg-none">Log out</p>
              </DropdownToggle>
              <DropdownMenu className="dropdown-navbar" right tag="ul">
                <NavLink tag="li" onClick={() => _myProfile()}>
                  <DropdownItem className="nav-item">My Profile</DropdownItem>
                </NavLink>
                {/* <NavLink tag="li">
                      <DropdownItem className="nav-item">Settings</DropdownItem>
                    </NavLink> */}
                <NavLink tag="li" onClick={() => _logout()}>
                  <DropdownItem className="nav-item">Log out</DropdownItem>
                </NavLink>
              </DropdownMenu>
            </UncontrolledDropdown>
            <li className="separator d-lg-none" />
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}


export default AdNavbar;

