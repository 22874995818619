import React from "react";
import Navbar from '../components/navbar'
import { Route } from 'react-router-dom'
import Licences from "./Licences";
import Client from "./Client";
import Templates from './templates'
import Updates from './updates'
import UserInfo from "./UserInfo";

const Dashboard = () => {
    return (
        <div className="wrapper">
            <div className="main-panel">
                <Navbar />
                <Route path="/dashboard/licenses" component={Licences} />
                <Route path="/dashboard/client" component={Client} />
                <Route path="/dashboard/templates" component={Templates} />
                <Route path="/dashboard/updates" component={Updates} />
                <Route path="/dashboard/userinfo" component={UserInfo} />
            </div>
        </div>
    )
}

export default Dashboard