import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, Form, Row, Col, Input, FormGroup, Label, ModalFooter, Button, } from 'reactstrap'
import { addUpdate } from '../../../http/http-calls'

const UpdateModal = (props) => {
    const [description, setDescription] = useState()

    const _addUpdate = () => {
        addUpdate({ description })
            .then(response => console.log(response))
            .catch(error => console.log(error))
    }

    return (
        <Modal isOpen={props.open} toggle={props.toggle} className="modal-dialog-centered  modal-dialog-scrollable">
        <ModalHeader toggle={props.toggle}>Create Update</ModalHeader>
        <ModalBody>
          <Form>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label>Description</Label>
                  <Input type="textarea" name="description" onChange={(e) => setDescription(e.target.value)}  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="modalBtnCancel" onClick={() => props.toggle()}>Cancel</Button>
          <Button color="secondary" className="modalBtnSave" onClick={_addUpdate}>Save</Button>
        </ModalFooter>
      </Modal>
    )
}

export default UpdateModal