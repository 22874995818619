import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

const UserInfoModal = (props) => {
    return (
        <>
            <Modal isOpen={props.open} toggle={props.toggle} className="modal-dialog-centered  modal-dialog-scrollable">
                <ModalHeader toggle={props.toggle} >Warning</ModalHeader>
                <ModalBody>
                  No data available
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="modalBtnSave" onClick={props.onDelete}>Close</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default UserInfoModal
