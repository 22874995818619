import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    InputGroup,
    FormGroup,
    Badge,
    InputGroupAddon,
    Input,
    //CustomInput,
    Table,
    Label,
    //ListGroup,
    Button,
} from "reactstrap";
import PaginationList from '../components/PaginationList';
import NewLicence from '../components/Modals/NewLicence';
import { lisence as getLicense, createLisence, deleteLicense } from '../../http/http-calls'
import WarningModal from '../components/Modals/warningModals'

const Licences = () => {
    const [modal, setModal] = useState(false);
    const [warning, setWarning] = useState({
        open: false,
        index: null
    })
    const [lisences, setLisences] = useState([])
    const [pageInfo, setPageInfo] = useState({ page: 1, totalPages: 1 })
    const toggle = () => setModal(!modal);
    const [lisence, setLisence] = useState({ name: "", email: "", count: 0, phone: "" })
    const [search, setSearch] = useState("")

    useEffect(() => {
        getLicenseList(pageInfo.page)
    }, [])

    const getLicenseList = (pageNo, search) => {
        getLicense(pageNo, 10, search)
            .then(response => {
                console.log(response)
                setLisences(response.lisences.edges)
                setPageInfo(prev => {
                    prev.totalPages = response.lisences.pageInfo[0]?.pages
                    return { ...prev }
                })
            })
            .catch(error => console.log(error))
    }

    const onLicenseSave = (data) => {
        createLisence(data)
            .then(response => {
                console.log(response)
                setModal(false)
                getLicenseList(pageInfo.page);
            })
            .catch(error => console.log(error))
    }

    const handleOnChange = (field, value) => {
        setLisence(prev => {
            prev[field] = value
            return { ...prev }
        })
    }

    const onPageChange = (page) => {
        setPageInfo(prev => {
            prev.page = page
            return { ...prev }
        })
        getLicenseList(page)
    }

    const onDelete = () => {
        deleteLicense(lisences[warning.index]._id)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
        setWarning(prev => {
            prev.open = false
            return { ...prev }
        })
        getLicenseList(pageInfo.page)
    }

    const onEditClick = (index) => {
        let data = { ...lisences[index] }
        console.log(data)
        setLisence({ name: data.owner.name, email: data.owner.email, count: data.noOfLicenses, phone: data.owner.phone })
        setModal(true)
    }

    const _onSearchChange = (e) => {
        setSearch(e.target.value)
    }

    const _onSearchSubmit = (e) => {
        e.preventDefault()
        getLicenseList(pageInfo.page, search)
    }

    const addDays = (date, days) => {
        const dateObj = new Date(date)
        dateObj.setDate(dateObj.getDate() + days)
        return dateObj.toDateString()
    }

    return (
        <>

            <div className="content">
                <Row>
                    <Col sm={8} className="m-auto">
                        <Card className="userInfo">
                            <CardHeader className="tableFilterWrap">
                                Licences
                                <div className="">
                                    <Button className="addBtn ml-3" onClick={toggle}>
                                        New Licence
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row form>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label>Status</Label>
                                            <Input type="select" name="status" >
                                                <option>Select Status</option>
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    {/* <Col md={2}>
                                        <Label>Date</Label>
                                        <Input type="date" name="Licences" />
                                    </Col> */}
                                    <Col md={33} className="ml-auto">
                                        <label></label>
                                        <form onSubmit={_onSearchSubmit}>

                                            <InputGroup className="searchBox">
                                                <Input placeholder="search..." onChange={_onSearchChange} />
                                                <InputGroupAddon addonType="append">
                                                    <i className="fa fa-search"></i>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </form>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <Table className="tablesorter" responsive>
                                            <thead>
                                                <tr>

                                                    <th>Licence</th>
                                                    <th>Name</th>
                                                    <th>Date Purchase</th>
                                                    <th>Status</th>
                                                    <th>Date Expiry</th>
                                                    <th style={{ width: 150, textAlign: 'right' }}>
                                                        Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {lisences.map((each, index) => (<tr>
                                                    <td key={index}>
                                                        {each.licenseKey}
                                                    </td>
                                                    <td>
                                                        {each.owner?.name}
                                                    </td>
                                                    <td>
                                                        {(new Date(each.createdAt)).toDateString()}
                                                    </td>
                                                    <td>
                                                        <Badge color="success">Active</Badge>
                                                    </td>
                                                    <td>
                                                        {addDays(each.createdAt, each.validityInDays)}
                                                    </td>

                                                    <td style={{ width: 250, textAlign: 'right' }}>
                                                        <Button className="viewBtn mr-3" onClick={() => onEditClick(index)}>
                                                            <i className="fa fa-pencil"></i>
                                                        </Button>
                                                        <Button className="stopBtn mr-3" onClick={() => setWarning(prev => {
                                                            prev.open = true
                                                            prev.index = index
                                                            return { ...prev }
                                                        })}>
                                                            <i className="fa fa-trash"></i>
                                                        </Button>
                                                    </td>
                                                </tr>))}
                                            </tbody>
                                        </Table>
                                        <PaginationList pageInfo={pageInfo} onPageChange={onPageChange} />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            <NewLicence open={modal} toggle={toggle} onSave={onLicenseSave} handleOnChange={handleOnChange} lisence={lisence} />
            <WarningModal open={warning.open} toggle={() => setWarning(prev => !prev)} onDelete={onDelete} />
        </>
    )
}

export default Licences;
