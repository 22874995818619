import React, { useState, useEffect } from 'react'
import { Col, Card, CardHeader, Input, Button, CardBody, Table, Label, Row } from 'reactstrap'
import { addTemplate, getTemplates, deleteTemplate } from '../../http/http-calls'

const Templates = () => {
    const [templateFiles, setTemplates] = useState([])

    useEffect(() => {
        getTemplateList()
    }, [])

    const getTemplateList = () => {
        getTemplates()
            .then(response => {
                console.log(response)
                setTemplates(response.templates)
            })
            .catch(error => console.log(error))
    }

    const _uploadTemplates = (event) => {
        const [file] = event.target.files
        console.log(file)
        const formData = new FormData()
        formData.append("file", file)
        addTemplate(formData)
            .then(response => getTemplateList())
            .catch(error => console.log(error))
        getTemplateList()
    }

    const _onDelete = (template) => {  
        const { _id } = template
        deleteTemplate(_id)
            .then(response => {
                getTemplateList()
            })
            .catch(error => console.log(error))
    }

    return (
        <div className="content">
            <Row>
                <Col sm={8} className="m-auto">
                    <Card className="userInfo">
                        <CardHeader className="tableFilterWrap withUpload">
                            <Input
                                type="text"
                                placeholder="Search..."
                                style={{ width: 180 }}
                                onChange={(e) => { }}
                            />
                            <div className="upload_Input">
                                <Input type="file" id="fileUpload" className="" onChange={_uploadTemplates} />
                                <Label for="fileUpload">Upload</Label>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Table className="tablesorter" responsive>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Base bin</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {React.Children.toArray(templateFiles.map((each) => (
                                        <tr>
                                            <th>{each.name}</th>
                                            <th>{each.description}</th>
                                            <th>{each.baseBin}</th>
                                            <th>
                                                <Button type="button" className="stopBtn mr-3 btn btn-secondary" onClick={() => _onDelete(each)}>
                                                    <i className="fa fa-trash"></i>
                                                </Button>
                                            </th>
                                        </tr>)
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Templates