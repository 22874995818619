import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PaginationList = (props) => {
  console.log(props)
  return (
    <>
      <Pagination aria-label="Page navigation example" className="mt-3">
        <PaginationItem disabled={!props.pageInfo.totalPage > 1}>
          <PaginationLink previous onClick={() => props.onPageChange(props.pageInfo.page - 1)} />
        </PaginationItem>

        {[...Array(props.pageInfo.totalPages)].map((each, index) => (<PaginationItem onClick={() => props.onPageChange(index + 1)} className={props.pageInfo.page === index + 1 ? "active": ""}>
          <PaginationLink>
            {index + 1}
          </PaginationLink>
        </PaginationItem>))}

        <PaginationItem disabled={!props.pageInfo.totalPage > 1}>
          <PaginationLink next onClick={() => props.onPageChange(props.pageInfo.page + 1)} />
        </PaginationItem>

      </Pagination>
    </>
  )
}

export default PaginationList
