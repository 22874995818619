import { Route, Redirect } from "react-router-dom";
import React from "react";
// import { store } from '../../../redux/store';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  let state = localStorage.getItem("user")
  if (state) {
    state = JSON.parse(state)
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (state) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: rest.redirectRoute,
                extras: { ...rest.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
