import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { login } from '../../../http/http-calls'
import { useHistory } from 'react-router-dom'

const Login = () => {
  const [user, setUser] = useState({ userName: "", password: "" })
  const [errors, setErrros] = useState()
  const [isVisible, setIsVisible] = useState(false)

  const history = useHistory()

  const _login = (e) => {
    e.preventDefault()
    console.log(user)
    login({ handle: user.userName, password: user.password })
      .then(response => {
        console.log(response)
        localStorage.setItem("user", JSON.stringify({ user: response.handle, token: response.token }))
        history.replace("/dashboard/client")
      })
      .catch(error => { })
  }

  const _handleOnChange = (field, value) => {
    console.log(field, value)
    setUser(prev => {
      prev[field] = value
      return { ...prev }
    })
  }

  const _handleVisibilityToggle = () => {
    setIsVisible(prev => !prev)
  }

  return (
    <div className="full-page login-page">

      <div className="authFormWrap">
        <Form onSubmit={(e) => _login(e)}>
          <Card className="card-white">
            <CardHeader>
              <img alt="EVOS Logo" className="companyLogo" src="../../../assets/img/evos-logo.png" />

              <h1 className="card-title">Login</h1>
            </CardHeader>
            <CardBody>
              <Label>Username</Label>
              <FormGroup>
                <i className="fa fa-user formIcon"></i>
                <Input
                  placeholder="Enter"
                  type="text"
                  value={user.userName}
                  onChange={(e) => {
                    e.preventDefault();
                    console.log(e.target.value)
                    _handleOnChange("userName", e.target.value)
                  }}
                />
                {errors && (
                  <div className="validation-error">
                    {errors.userName}
                  </div>
                )}
              </FormGroup>

              <Label>Password</Label>
              <FormGroup>
                <i className="fa fa-lock formIcon lockIcon"></i>
                <Input
                  placeholder="Enter"
                  type={isVisible ? "text" : "password"}
                  className="passwordField"
                  value={user.password}
                  onChange={(e) =>
                    _handleOnChange("password", e.target.value)
                  }
                />
                <i className={true ? "fa fa-eye showPassword" : "fa fa-eye-slash showPassword"}
                  onClick={() => _handleVisibilityToggle()}
                ></i>
                {/* <i className="fa fa-eye showPassword d-none"></i> */}
                {errors && (
                  <div className="validation-error">
                    {errors.password}
                  </div>
                )}
              </FormGroup>
              <div className="text-right">
                <Link className="link forgotPassword" to="/forgot-password">
                  Forgot Password?
                </Link>
              </div>

              <Button
                className="btn themeBtn"
                type="submit"
              >
                Login
              </Button>

              {/* <Link className="link createAccount" to="/signup">
                New to EVOS? <span>Create Account</span>
              </Link> */}
            </CardBody>
          </Card>
        </Form>
      </div>

      <div className="authFooter">
        EVOS &#169; {new Date().getFullYear()}.

        <div style={{ display: 'none' }}>
          Powered By: <a href="https://www.logic-square.com/" target="_blank" rel="noreferrer">Logic Square</a>
        </div>
        <div>
          Powered By: <a href="http://www.gurooit.com" target="_blank" rel="noreferrer">GuROO</a>
        </div>
      </div>
    </div>
  )
}

export default Login

