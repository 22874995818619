import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const Layout = () => {
    const history = useHistory()

    useEffect(() => {
        history.replace("/login")
    }, [])

    return (
        <div className="wrapper">
        </div>
    )
}

export default Layout