import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

const WarningModal = (props) => {
    return (
        <Modal isOpen={props.open} toggle={props.toggle} className="modal-dialog-centered  modal-dialog-scrollable">
            <ModalHeader toggle={props.toggle} >Warning</ModalHeader>
            <ModalBody>
                Are You Sure you want to delete ?
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" className="modalBtnSave" onClick={props.onDelete}>Delete</Button>
            </ModalFooter>
        </Modal>
    )
}

export default WarningModal