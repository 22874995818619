import React, { useEffect, useState } from 'react';
import Navbar from '../components/navbar';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    InputGroup,
    FormGroup,
    Badge,
    InputGroupAddon,
    Input,
    CustomInput,
    Table,
    Label,
    //ListGroup,
    //Button,
} from "reactstrap";
import PaginationList from '../components/PaginationList';

import { getClients } from '../../http/http-calls'

const Client = () => {
    const [clients, setClients] = useState([])
    const [pageInfo, setPageInfo] = useState({ page: 1, totalPages: 1 })

    useEffect(() => {
        getClientsList()
    }, [])

    const getClientsList = () => {
        getClients(pageInfo.page, 10)
            .then((response) => {
                console.log(response)
                setClients(response.clients.edges)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getClientsList()
    }, [pageInfo])

    const onPageChange = (page) => {
        setPageInfo(prev => {
            prev.page = page
            return { ...prev }
        })
    }

    return (

        <div className="content">

            <Row>
                <Col sm={8} className="m-auto">
                    <Card className="userInfo">
                        <CardHeader>Clients</CardHeader>
                        <CardBody>
                            <Row form>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label>Status</Label>
                                        <Input type="select" name="status" >
                                            <option>Select Status</option>
                                            <option>Active</option>
                                            <option>Inactive</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Label>Licences</Label>
                                    <Input type="select" name="Licences" >
                                        <option>0</option>
                                        <option>1</option>
                                        <option>2-5</option>
                                        <option>6-10</option>
                                        <option>11-50</option>
                                        <option>50+</option>
                                    </Input>
                                </Col>
                                <Col md={33} className="ml-auto">
                                    <label></label>
                                    <InputGroup className="searchBox">
                                        <Input placeholder="search..." />
                                        <InputGroupAddon addonType="append">
                                            <i className="fa fa-search"></i>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <Table className="tablesorter" responsive>
                                        <thead>
                                            <tr>
                                                <th style={{ width: 100 }}>
                                                    <CustomInput type="checkbox" id="example1" label="" />
                                                </th>
                                                <th>Client</th>
                                                <th>POC</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                {/* <th>Licences</th> */}
                                                <th style={{ width: 120, textAlign: 'right' }}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {clients.map(each => <tr>
                                                <td>
                                                    <CustomInput type="checkbox" id="example2" label="" />
                                                </td>
                                                <td>
                                                    {each.name}
                                                </td>
                                                <td>
                                                    John
                                                </td>
                                                <td>
                                                    {each.email}
                                                </td>
                                                <td>
                                                    {each.phone}
                                                </td>
                                                {/* <td>
                                                            5
                                                        </td> */}
                                                <td style={{ width: 150, textAlign: 'right' }}>
                                                    <Badge color="success">Active</Badge>
                                                </td>
                                            </tr>)}

                                        </tbody>
                                    </Table>

                                    <PaginationList pageInfo={pageInfo} onPageChange={onPageChange} />

                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Client;
