import React, { useState, useEffect } from 'react'
import UpdateModal from '../components/Modals/updateModal'
import { Row, Col, Card, CardHeader, Input, Table, Button, CardBody } from 'reactstrap'
import { getUpdates } from '../../http/http-calls'


const Updates = (props) => {
    const [updates, setUpdates] = useState([])
    const [modal, setModal] = useState(false)

    useEffect(() => {
        getUpdates()
            .then(response => {
                console.log(response)
                setUpdates(response.updates)
            })
            .catch(error => console.log(error))
    }, [])

    const _onDelete = () => {

    }

    const _toggle = () => {
        console.log("toggle ===> ")
        setModal(prev => !prev)
    }

    return (
        <div className="content">
            <Row>
                <Col sm={8} className="m-auto">
                    <Card className="userInfo">
                        <CardHeader className="tableFilterWrap withUpload">
                            <Input
                                type="text"
                                placeholder="Search..."
                                style={{ width: 180 }}
                            // onChange={(e) => { }}
                            />
                            <div className="">
                                <Button className="addBtn ml-3" onClick={_toggle}>
                                    New Updates
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Table className="tablesorter" responsive>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Description</th>
                                        {/* <th>Base bin</th> */}
                                        {/* <th>Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {React.Children.toArray(updates.map((each) => (
                                        <tr>
                                            <th>{each.name || each.uuid}</th>
                                            <th>{each.description}</th>
                                            {/* <th>{each.baseBin}</th> */}
                                            {/* <th>
                                                <Button type="button" className="stopBtn mr-3 btn btn-secondary" onClick={() => _onDelete(each)}>
                                                    <i className="fa fa-trash"></i>
                                                </Button>
                                            </th> */}
                                        </tr>)
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <UpdateModal open={modal} toggle={_toggle}></UpdateModal>
        </div>
    )
}

export default Updates