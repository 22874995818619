import {
  makeGetRequest,
  makePostRequest,
  // makePutRequest,
  makeDeleteRequest,
  uploadFileMultiPart,
  // makeGetFileRequest,
} from "./http-service";
import config from "../config/index";

export const login = ({ handle, password }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "admin/login", false, {
      handle,
      password,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const lisence = (page, limit, search) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `admin/lisences?page=${page}&limit=${limit}${search !== undefined && search !== "" ? `&search=${search}` : ""}`, true)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export const createLisence = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + `admin/lisence`, true, data)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export const getClients = (page, limit) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `admin/clients?page=${page}&limit=${limit}`, true)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export const deleteLicense = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(config.baseURL + `admin/lisence/${id}`, true)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export const addTemplate = (data) => {
  return new Promise((resolve, reject) => {
    uploadFileMultiPart(config.baseURL + `admin/vmtemplate`, true, data)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export const getTemplates = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `admin/vmtemplate`, true)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export const deleteTemplate = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(config.baseURL + `admin/vmtemplate/${id}`, true)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export const addUpdate = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + `update`, true, data)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export const getUpdates = (data) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `update`, true)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export const getUserInfo = (data) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + `userdata`, true)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}